var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', {
    staticClass: "auth-inner m-0"
  }, [_c('b-link', {
    staticClass: "brand-logo",
    staticStyle: {
      "left": "5%"
    }
  }, [_c('h1', {
    staticClass: "brand-text text-primary"
  }, [_vm._v("ELP")])]), _c('b-col', {
    staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-col', {
    staticClass: "px-xl-2 mx-auto",
    attrs: {
      "sm": "8",
      "md": "6",
      "lg": "12"
    }
  }, [_c('h2', {
    staticClass: "font-weight-bold mb-1",
    attrs: {
      "title-tag": "h2"
    }
  }, [_vm._v(" Ganti Kata Sandi 🔒 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v("Buat Kata Sandi Baru Kamu")]), _c('validation-observer', {
    ref: "resetPassword",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-reset-password-form mt-2",
          attrs: {
            "method": "POST"
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Kata Sandi Baru",
            "label-for": "reset-password-new"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Kata Sandi",
            "vid": "Kata Sandi",
            "rules": "required|min:8"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "reset-password-new",
                  "type": _vm.password1FieldType,
                  "state": errors.length > 0 ? false : null,
                  "name": "reset-password-new",
                  "placeholder": "Masukkan Kata Sandi"
                },
                model: {
                  value: _vm.password,
                  callback: function callback($$v) {
                    _vm.password = $$v;
                  },
                  expression: "password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.password1ToggleIcon
                },
                on: {
                  "click": _vm.togglePassword1Visibility
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "reset-password-confirm",
            "label": "Konfirmasi Kata Sandi"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Konfirmasi Kata Sandi",
            "rules": "required|confirmed:Kata Sandi"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "reset-password-confirm",
                  "type": _vm.password2FieldType,
                  "state": errors.length > 0 ? false : null,
                  "name": "reset-password-confirm",
                  "placeholder": "Masukkan Kata Sandi"
                },
                model: {
                  value: _vm.cPassword,
                  callback: function callback($$v) {
                    _vm.cPassword = $$v;
                  },
                  expression: "cPassword"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.password2ToggleIcon
                },
                on: {
                  "click": _vm.togglePassword2Visibility
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-button', {
          attrs: {
            "block": "",
            "type": "submit",
            "disabled": invalid,
            "variant": "primary"
          }
        }, [_vm._v(" Simpan ")])], 1)];
      }
    }])
  }), _c('p', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'auth-login'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Kembali Login ")], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "d-none d-lg-flex align-items-center p-5",
    attrs: {
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "w-100 d-lg-flex align-items-center justify-content-center px-5"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.imgUrl,
      "fluid": "",
      "alt": "Register V2"
    }
  })], 1)]), _c('p', {
    staticClass: "clearfix mb-0",
    staticStyle: {
      "position": "absolute",
      "top": "93%",
      "left": "5%"
    }
  }, [_c('span', {
    staticClass: "float-md-left d-block d-md-inline-block mt-25"
  }, [_vm._v(" © " + _vm._s(new Date().getFullYear()) + " Einstein Learning Plus "), _c('span', {
    staticClass: "d-none d-sm-inline-block"
  }, [_vm._v(". All rights Reserved")])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }